<template lang="html" src="./uploadOrgParts.template.vue"></template>

<style lang="scss" src="./uploadOrgParts.scss"></style>

<script>
import { UploadManager, ProgressSingle, ApiErrorParser, FileConverter } from '@cloudmanufacturingtechnologies/portal-components';

const i18nData = require('./uploadOrgParts.i18n');

export default {
  name: 'UploadOrgParts',
  components: { UploadManager, ProgressSingle },
  props: {
    warehouseParts: {
      type: Array,
      required: true,
    },
  },
  i18n: {
    messages: i18nData,
    dateTimeFormats: i18nData,
    numberFormats: i18nData,
  },
  data() {
    return {
      rules: {
        required: (value) => !!value.trim() || this.$t('ErrorNoValue'),

        nameMustBeLessThan: (value) =>
          (!!value && value.length <= 100) || this.$t('NameMustBeLess'),
        uniqueNameWarehouse: (value) =>
          !this.warehousePartsName.includes(value.toLowerCase()) ||
          this.$t('ErrorNameExistWarehouse'),
        uniqueName: (value) =>
          this.isUniqueName(value) || this.$t('ErrorNameExist'),
        uniqueRefWarehouse: (value) =>
          !(
            value &&
            this.warehousePartsInternalReference.includes(value.toLowerCase())
          ) || this.$t('ErrorReferenceExistWarehouse'),
        uniqueRef: (value) =>
          this.isUniqueRef(value) || this.$t('ErrorReferenceExist'),
        internalRefMustBeLessThan: (value) =>
          value && value.length >= 100
            ? this.$t('InternalRefMustBeLess')
            : true,
        descriptionMustBeLessThan: (value) =>
          value && value.length >= 300
            ? this.$t('DescriptionMustBeLess')
            : true,
      },
      warehousePartsName: [],
      warehousePartsInternalReference: [],
      progressTotal: null,
      uploadsTotal: [],
      partsFile: null,
      partsEditData: [],
      basket: null,
      step2UploadComplete: false,
      addToBasketInProgress: false,
      openModal: false,
      uploadsCompleted: [],
      uploadPartsErrorCount: [],
      wrongExtensionMessageError: [],
      step: 1,
      modal: false,
      currentPartUpload: null,
      currentPartCompressed: null,
      partsFilesSelected: []
    };
  },
  created() {
    // this.rules = ;
    this.resetData();
  },
  methods: {
    resetData() {
      this.warehousePartsName = [];
      this.warehousePartsInternalReference = [];
      this.progressTotal = null;
      this.uploadsTotal = [];
      this.partsFile = null;
      this.partsEditData = [];
      this.basket = null;
      this.step2UploadComplete = false;
      this.openModal = false;
      this.uploadsCompleted = [];
      this.uploadPartsErrorCount = [];
      this.wrongExtensionMessageError = [];
      this.step = 1;
      this.modal = false;

      this.warehouseParts.forEach((part) => {
        this.warehousePartsName.push(part.name.toLowerCase());
        if(part.internalReference) {
          this.warehousePartsInternalReference.push(
            part.internalReference.toLowerCase()
          );
        }
      });
    },
    close() {
      this.modal = false;
      this.resetData();
    },
    createUploadTotalListAndTakeNextStep(data, table) {
      table.push(data);
      this.uploadsTotal.push(table[this.uploadsTotal.length]);
      this.currentPartUpload = null;
      if (this.uploadsTotal.length < this.partsFile.length) {
        this.createPartsAndUpload();
      } else {
        this.step2UploadComplete = true;
      }
    },
    openFileSelector() {
      this.resetData();
      const fileSelector = this.$refs.partsUploadFiles;
      fileSelector.value = '';
      fileSelector.click();
    },
    inputFilesChanged() {
      const fileSelector = this.$refs.partsUploadFiles;
      if(fileSelector.value && fileSelector.files.length > 0) {
        this.partsFilesSelectedChanged(fileSelector.files);
      }
    },
    partsFilesSelectedChanged(files) {
      this.partsFilesSelected = [];
      this.wrongExtensionMessageError = [];
      this.partsFilesSelected = files;
      this.preparePartsFiles();
    },
    preparePartsFiles() {
      if(this.partsFilesSelected.length) {
        const filesList = [];
        for (let i = 0; i < this.partsFilesSelected.length; i++) {
          const fileName = this.partsFilesSelected[i].name;
          const regex = /(.stl|.3mf|.step|.stp|.obj|.iges|.igs)$/i;
          if (regex.test(fileName)) {
            filesList.push(this.partsFilesSelected[i]);
          } else {
            this.wrongExtensionMessageError.push(fileName);
          }
        }
        this.partsFile = filesList;
        /**
         * Verify Quota
         */
        if (
          this.partsFile.length > 0 &&
          this.$appConfig.brand.features.warehousePartQuota >=
          this.warehouseParts.length + this.partsFile.length
        ) {
          this.preparePartsEditor();
        } else {
          /**
           * ERROR QUOTA
           */
          this.step = 0;
          /**
           * LOAD MODAL WITH CONTENT
           *
           */
          this.modal = true;
        }
      }
    },
    preparePartsEditor() {
      for (let i = 0; i < this.partsFile.length; i++) {
        const fileName = this.partsFile[i].name.replace(/\.stl|\.3mf|\.stp|\.step|\.obj|\.iges|\.igs/i, '');
        this.partsEditData.push({
          fileID: i,
          name: fileName,
          description: '',
          internalReference: '',
        });
      }
      /**
       * LOAD MODAL WITH CONTENT
       */
      this.modal = true;
    },
    isUniqueName(name) {
      let count = 0;
      let unique = true;
      this.partsEditData.forEach((part) => {
        if (name.toLowerCase() === part.name.toLowerCase()) {
          if (count === 1) {
            unique = false;
            return unique;
          }
          count = 1;
        }
      });
      /**
       * RETURN VALID STATUS
       */
      return unique;
    },
    isUniqueRef(ref) {
      if (ref.trim() === '') {
        return true;
      }
      let count = 0;
      let unique = true;
      this.partsEditData.forEach((part) => {
        if (ref.toLowerCase() === part.internalReference.toLowerCase()) {
          if (count === 1) {
            unique = false;
            return unique;
          }
          count = 1;
        }
      });
      /**
       * RETURN VALID STATUS
       */
      return unique;
    },
    validate() {
      let valid = true;
      for (let row = 0; row < this.partsEditData.length; row++) {
        this.$refs[`uploadPart-${row}-0`][0].validate(true);
        this.$refs[`uploadPart-${row}-1`][0].validate(true);
        this.$refs[`uploadPart-${row}-2`][0].validate(true);
        if (
          !this.$refs[`uploadPart-${row}-0`][0].valid ||
          !this.$refs[`uploadPart-${row}-1`][0].valid ||
          !this.$refs[`uploadPart-${row}-2`][0].valid
        ) {
          valid = false;
        }
      }
      /**
       * RETURN VALID STATUS
       */
      return valid;
    },
    async createPartsAndUpload() {
      /**
       * Create the part
       */
      const part = new this.$BcmModel.AddBrandPartBody(
        this.partsEditData[this.uploadsTotal.length].name,
        this.partsEditData[this.uploadsTotal.length].description,
        this.partsEditData[this.uploadsTotal.length].internalReference
      );
      let fileToUpload = null;
      let partUUID = null;
      await this.$apiInstance
        .addBrandPart(this.$appConfig.currentBrand, part)
        .then(
          (data) => {
            /**
             * UPLOAD THE PART FILE
             */
            partUUID = data._id; // String | The UUID of the Part
            fileToUpload = this.partsFile[
              this.partsEditData[this.uploadsTotal.length].fileID
            ];
            this.partsEditData[this.uploadsTotal.length].partUUID = partUUID;

            const fileExt = fileToUpload.name.split('.').pop().toLowerCase();

            if(fileExt === 'stl') {
              const self = this;
              /**
               * Convert to BLS
               */
              let buffer = null;
              let fileName = fileToUpload.name;
              fileName = fileName.substring(0, fileName.length - 4);

              const reader = new FileReader();
              reader.onloadend = function() {
                buffer = reader.result;
                self.currentPartCompressed = data;
                FileConverter.convertFile(buffer, 'stl', 'bls').then(newData => {
                  self.currentPartCompressed = null;
                  self.currentPartUpload = data;
                  const newFileToUpload = new File([newData], fileName + '.bls');

                  self.$refs.UploadManagerRef.uploadPart(
                    newFileToUpload,
                    self.$appConfig.currentBrand,
                    partUUID
                  );

                });
              };

              reader.readAsArrayBuffer(fileToUpload);

            } else {

              this.$refs.UploadManagerRef.uploadPart(
                fileToUpload,
                this.$appConfig.currentBrand,
                partUUID
              );

            }
          },
          (error) => {
            /**
             * ERROR DURING PART CREATE
             */
            this.createUploadTotalListAndTakeNextStep(
              {
                name: this.partsEditData[this.uploadsTotal.length].name,
                error: ApiErrorParser.parse(error),
              },
              this.uploadPartsErrorCount
            );
            this.progressUpload(0);
          }
        );
    },
    listUploadsCompleted() {
      /**
       *  At the Success Event we insert the part in the uploadsCompleted Table and in the uploadsTotal table
       */
      this.createUploadTotalListAndTakeNextStep(
        this.partsEditData[this.uploadsTotal.length],
        this.uploadsCompleted
      );
    },
    listUploadsError(error) {
      /**
       *  At the Success Event we insert the part in the uploadsCompleted Table and in the uploadsTotal table
       *  and read the error
       */
      let errorMessage = null;
      if(error.response) {
        if(JSON.parse(error.response)?.message === 'Part file is too large') {
          errorMessage = this.$t('PartTooLarge');
        }
      }
      if(!errorMessage) {
        errorMessage = ApiErrorParser.parse(error);
      }
      this.currentPartUpload = null;
      this.createUploadTotalListAndTakeNextStep(
        {
          name: this.partsEditData[this.uploadsTotal.length].name,
          error: errorMessage,
        },
        this.uploadPartsErrorCount
      );
    },
    progressUpload(done) {
      /**
       *  Check the progress
       */
      this.progressTotal =
        (done + 100 * this.uploadsTotal.length) / this.partsFile.length;
    },
    buttonCancelClick() {
      if (this.step === 2) {
        this.$emit('PartsUploaded');
        this.close();
      }
    },
    buttonSaveClick() {
      this.uploadPartsErrorCount = [];
      if (this.validate()) {
        /**
         * Start step 2: Create Parts / Upload file
         */
        this.step = 2;
        this.createPartsAndUpload();
      }
    },
  },
};
</script>
