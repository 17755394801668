var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "module-uploadOrgParts"
  }, [_c('v-row', [_c('v-col', {
    staticClass: "pb-0 pt-4"
  }, [_c('UploadManager', {
    ref: "UploadManagerRef",
    on: {
      "progress": _vm.progressUpload,
      "success": _vm.listUploadsCompleted,
      "error": _vm.listUploadsError
    }
  }), _c('v-btn', {
    staticClass: "text-none",
    attrs: {
      "color": "info"
    },
    on: {
      "click": _vm.openFileSelector
    }
  }, [_c('v-icon', {
    staticClass: "mr-2",
    attrs: {
      "small": ""
    }
  }, [_vm._v(" fas fa-cloud-upload-alt ")]), _vm._v(" " + _vm._s(_vm.$t('UploadParts')) + " ")], 1), _c('input', {
    ref: "partsUploadFiles",
    staticClass: "d-none",
    attrs: {
      "type": "file",
      "accept": ".stl,.3mf,.step,.stp,.obj,.iges,.igs",
      "multiple": ""
    },
    on: {
      "change": _vm.inputFilesChanged
    }
  })], 1)], 1), _c('v-row', [_c('v-col', {
    staticClass: "py-0 text-caption font-weight-light",
    attrs: {
      "align": "center"
    }
  }, [_c('span', [_vm._v(" " + _vm._s(_vm.$t('AllowedFormats')) + " : STL, 3MF, STEP*, OBJ*, IGS* (*beta) ")])])], 1), _c('v-dialog', {
    attrs: {
      "persistent": "",
      "max-width": "50vw"
    },
    model: {
      value: _vm.modal,
      callback: function ($$v) {
        _vm.modal = $$v;
      },
      expression: "modal"
    }
  }, [_c('v-card', [_c('v-card-title', {
    staticClass: "headline justify-center mb-7"
  }, [_vm.step === 0 && _vm.wrongExtensionMessageError.length === 0 ? [_vm._v(" " + _vm._s(_vm.$t('QuotaLimitReached')) + " ")] : _vm._e(), _vm.step === 0 && _vm.wrongExtensionMessageError.length > 0 ? [_vm._v(" " + _vm._s(_vm.$t('wrongExtension')) + " ")] : _vm._e(), _vm.step === 1 ? [_vm._v(" " + _vm._s(_vm.$t('EditPartsinformation')) + " ")] : _vm._e(), _vm.step === 2 ? [_vm._v(" " + _vm._s(_vm.$t('UploadingParts')) + " ")] : _vm._e()], 2), _c('v-card-text', [_vm.step <= 1 && _vm.wrongExtensionMessageError.length > 0 ? _c('v-alert', {
    attrs: {
      "type": "error",
      "outlined": "",
      "text": "",
      "prominent": ""
    }
  }, [_c('p', [_vm._v(" " + _vm._s(_vm.$t('WrongFileFormatText')) + ": ")]), _vm._l(_vm.wrongExtensionMessageError, function (fileExtension, index) {
    return _c('ul', {
      key: index
    }, [_c('li', [_vm._v(_vm._s(fileExtension))])]);
  }), _c('p', {
    staticClass: "mt-4 mb-0"
  }, [_vm._v(" " + _vm._s(_vm.$t('AllowedFormatsList')) + " ")])], 2) : _vm._e(), _vm.step === 0 && _vm.wrongExtensionMessageError.length === 0 ? _c('v-alert', {
    attrs: {
      "type": "info",
      "outlined": ""
    }
  }, [_vm._v(" " + _vm._s(_vm.$t('QuotaErrorMessageContact')) + " ")]) : _vm._e(), _vm.step === 1 ? _c('div', {
    attrs: {
      "id": "step1"
    }
  }, [_c('v-simple-table', {
    attrs: {
      "dense": ""
    }
  }, [_c('thead', [_c('tr', [_c('th', {
    staticClass: "text-left",
    attrs: {
      "scope": "col"
    }
  }, [_vm._v(" " + _vm._s(_vm.$t('Name')) + " ")]), _c('th', {
    staticClass: "text-left",
    attrs: {
      "scope": "col"
    }
  }, [_vm._v(" " + _vm._s(_vm.$t('Description')) + " ")]), _c('th', {
    staticClass: "text-left",
    attrs: {
      "scope": "col"
    }
  }, [_vm._v(" " + _vm._s(_vm.$t('InternalReference')) + " ")])])]), _c('tbody', _vm._l(_vm.partsEditData, function (part, key) {
    return _c('tr', {
      key: key
    }, [_c('td', {
      staticStyle: {
        "border-bottom": "none !important"
      }
    }, [_c('v-text-field', {
      ref: `uploadPart-${key}-0`,
      refInFor: true,
      staticClass: "my-2 inputTextField",
      attrs: {
        "solo": "",
        "dense": "",
        "rules": [_vm.rules.required, _vm.rules.uniqueNameWarehouse, _vm.rules.uniqueName, _vm.rules.nameMustBeLessThan]
      },
      on: {
        "change": _vm.validate
      },
      model: {
        value: part.name,
        callback: function ($$v) {
          _vm.$set(part, "name", $$v);
        },
        expression: "part.name"
      }
    })], 1), _c('td', {
      staticStyle: {
        "border-bottom": "none !important"
      }
    }, [_c('v-text-field', {
      ref: `uploadPart-${key}-1`,
      refInFor: true,
      staticClass: "my-2 inputTextField",
      attrs: {
        "solo": "",
        "dense": "",
        "placeholder": _vm.$t('Optional'),
        "rules": [_vm.rules.descriptionMustBeLessThan]
      },
      on: {
        "change": _vm.validate
      },
      model: {
        value: part.description,
        callback: function ($$v) {
          _vm.$set(part, "description", $$v);
        },
        expression: "part.description"
      }
    })], 1), _c('td', {
      staticStyle: {
        "border-bottom": "none !important"
      }
    }, [_c('v-text-field', {
      ref: `uploadPart-${key}-2`,
      refInFor: true,
      staticClass: "my-2 inputTextField",
      attrs: {
        "solo": "",
        "dense": "",
        "placeholder": _vm.$t('Optional'),
        "rules": [_vm.rules.uniqueRefWarehouse, _vm.rules.uniqueRef, _vm.rules.internalRefMustBeLessThan]
      },
      on: {
        "change": _vm.validate
      },
      model: {
        value: part.internalReference,
        callback: function ($$v) {
          _vm.$set(part, "internalReference", $$v);
        },
        expression: "part.internalReference"
      }
    })], 1)]);
  }), 0)])], 1) : _vm._e(), _vm.step === 2 ? _c('div', {
    attrs: {
      "id": "step2"
    }
  }, [_c('ProgressSingle', {
    staticClass: "mb-8",
    attrs: {
      "value": Math.floor(_vm.progressTotal),
      "error": _vm.uploadPartsErrorCount.length > 0
    }
  }), _c('v-list', {
    attrs: {
      "rounded": "",
      "dense": ""
    }
  }, [_c('v-list-item-group', {
    attrs: {
      "color": "primary"
    }
  }, [_vm._l(_vm.uploadsCompleted, function (part, i) {
    return _c('v-list-item', {
      key: i
    }, [_c('v-list-item-icon', [_c('v-icon', {
      staticClass: "mr-1",
      attrs: {
        "x-small": "",
        "color": "success"
      }
    }, [_vm._v(" fas fa-check ")])], 1), _c('v-list-item-content', [_vm._v(" " + _vm._s(_vm.$t("SuccessAddPart", {
      partName: part.name
    })) + " ")])], 1);
  }), _vm._l(_vm.uploadPartsErrorCount, function (part, i) {
    return _c('v-list-item', {
      key: i
    }, [_c('v-list-item-icon', [_c('v-icon', {
      staticClass: "mr-1",
      attrs: {
        "x-small": "",
        "color": "error"
      }
    }, [_vm._v(" fas fa-times ")])], 1), _c('v-list-item-content', [_vm._v(" " + _vm._s(_vm.$t("ErrorAddPart", {
      partName: part.name
    })) + " " + _vm._s(part.error) + " ")])], 1);
  }), _vm.currentPartCompressed ? _c('v-list-item', [_c('v-list-item-icon', [_c('v-icon', {
    staticClass: "mr-1",
    attrs: {
      "x-small": "",
      "color": "info"
    }
  }, [_vm._v(" fas fa-spinner fa-pulse ")])], 1), _c('v-list-item-content', [_vm._v(" " + _vm._s(_vm.$t("CurrentPartCompressed", {
    partName: _vm.currentPartCompressed.name
  })) + " ")])], 1) : _vm._e(), _vm.currentPartUpload ? _c('v-list-item', [_c('v-list-item-icon', [_c('v-icon', {
    staticClass: "mr-1",
    attrs: {
      "x-small": "",
      "color": "info"
    }
  }, [_vm._v(" fas fa-spinner fa-pulse ")])], 1), _c('v-list-item-content', [_vm._v(" " + _vm._s(_vm.$t("CurrentPartUpload", {
    partName: _vm.currentPartUpload.name
  })) + " ")])], 1) : _vm._e()], 2)], 1)], 1) : _vm._e(), _c('div', {
    ref: "currentStepError"
  })], 1), _c('v-card-actions', [_c('v-spacer'), _vm.step < 1 ? _c('v-btn', {
    staticClass: "error text-none",
    on: {
      "click": _vm.close
    }
  }, [_c('v-icon', {
    staticClass: "mr-2",
    attrs: {
      "small": ""
    }
  }, [_vm._v(" fas fa-times ")]), _vm._v(" " + _vm._s(_vm.$t('Close')) + " ")], 1) : _vm._e(), _vm.step === 1 ? [_c('v-btn', {
    staticClass: "error text-none",
    on: {
      "click": _vm.resetData
    }
  }, [_c('v-icon', {
    staticClass: "mr-2",
    attrs: {
      "small": ""
    }
  }, [_vm._v(" fas fa-ban ")]), _vm._v(" " + _vm._s(_vm.$t('Cancel')) + " ")], 1), _c('v-btn', {
    staticClass: "orange text-none white--text",
    on: {
      "click": _vm.buttonSaveClick
    }
  }, [_vm._v(" " + _vm._s(_vm.$t('Next')) + " "), _c('v-icon', {
    staticClass: "ml-2",
    attrs: {
      "color": "white",
      "small": ""
    }
  }, [_vm._v(" fas fa-arrow-right ")])], 1)] : _vm._e(), _vm.step === 2 && _vm.step2UploadComplete ? [_c('v-btn', {
    ref: "closeButtonAddToBasket",
    staticClass: "info text-none",
    attrs: {
      "data-dismiss": "modal"
    },
    on: {
      "click": _vm.buttonCancelClick
    }
  }, [_c('v-icon', {
    staticClass: "mr-2",
    attrs: {
      "small": ""
    }
  }, [_vm._v(" fas fa-times ")]), _vm._v(" " + _vm._s(_vm.$t('Close')) + " ")], 1)] : _vm._e()], 2)], 1)], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }